@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

body {
	background: #f1eee9;
	font-family: "Roboto", sans-serif;
}

.main-content {
	padding-top: 100px;
	padding-bottom: 100px;
}

.post-block {
	background: #fff;
	padding: 25px;
	margin-bottom: 25px;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
	transition: all 0.2s;
}
.post-block img.author-img {
	height: 45px;
	width: 45px;
	border-radius: 45px;
}
.post-block img.author-img.author-img--small {
	height: 30px;
	width: 30px;
}
.post-block__content img,
.post-block__content video,
.post-block__content audio {
	max-width: 100%;
	border-radius: 5px;
}
.post-block .comment-view-box__comment {
	border-radius: 5px;
	background: #f5f5f5;
	padding: 15px;
}

/* Popup style */
.popup-box {
  position: fixed;
  z-index: 100;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 80vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  z-index: 100;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.require {
    color: #666;
}
